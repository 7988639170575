<template>
  <v-dialog v-model="show" max-width="40%" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" elevation="0" color="primary">
        <v-icon left> mdi-plus </v-icon>
        Add New Group
      </v-btn>
    </template>
    <v-card>
      <div style="position: absolute; align-self: end; right: 0">
        <v-btn
          icon
          @click="
            show = !show;
            $refs.addNewRoleForm.reset();
            addedUsersList = [userId];
          "
          class="ma-1"
          v-if="userProfile.data().userRole !== 'Super Admin'"
        >
          <v-icon color="red">mdi-window-close</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="
            show = !show;
            $refs.addNewRoleForm.reset();
            addedUsersList = [];
          "
          class="ma-1"
          v-else
        >
          <v-icon color="red">mdi-window-close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="justify-center"> Add New Group </v-card-title>
      <v-divider></v-divider>
      <v-progress-linear
        v-show="loading"
        indeterminate
        color="primary"
        background-opacity="0"
      >
      </v-progress-linear>
      <v-card-text>
        <v-form ref="addNewRoleForm" lazy-validation class="px-6">
          <v-text-field
            v-model="group.name"
            label="Group Name"
            :rules="rules.nameRules"
            required
          >
          </v-text-field>
          <v-textarea
            outlined
            label="Description"
            @keyup.enter="addNewRole"
            v-model="group.description"
          ></v-textarea>
          <v-row>
            <v-col cols="4">
              <v-checkbox label="Assign Kiosk" v-model="assignKiosk">
              </v-checkbox>
            </v-col>
            <v-col cols="8">
              <v-select
                v-if="assignKiosk"
                v-model="group.assignedKiosks"
                :rules="[(v) => !!v || 'Assign at-least one kiosk']"
                :items="kiosks"
                label="Assigned Kiosks"
                item-text="kioskName"
                item-value="kioskName"
                required
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip small v-if="index === 0">
                    <span>{{ item.kioskName }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ group.assignedKiosks.length - 1 }} others)
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-combobox
            v-model="group.users"
            :items="usersList"
            hide-selected
            multiple
            @change="group.users = []"
            label="Add Users"
            hide-details=""
            dense
            class="mb-5"
          >
            <template v-slot:item="data">
              <v-list-item-avatar>
                <v-avatar color="primary" size="36" class="white--text">
                  {{ getUserInitials(data.item.id).initial }}
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content @click="addParticipant(data.item)">
                <v-list-item-title
                  v-html="data.item.data().name"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-combobox>
          <v-sheet
            min-height="10"
            max-height="100"
            class="mb-5 overflow-y-auto"
            v-if="addedUsersList.length > 0"
          >
            <v-chip
              v-for="(user, i) in addedUsersList"
              :key="i"
              close
              class="ma-1"
              @click:close="removeUser(user)"
            >
              <v-avatar left class="white--text" color="primary">
                {{ getUserInitials(user).initial }}
              </v-avatar>
              {{ getUserInitials(user).name }}
            </v-chip>
          </v-sheet>
          <v-btn
            @keyup.enter="addNewGroup"
            @click="addNewGroup"
            color="primary"
            dark
            block
            large
          >
            Add Group
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  name: "addNewGroup",
  data() {
    return {
      show: null,
      loading: false,
      assignKiosk: false,
      addedUsersList: [],
      group: {
        name: "",
        description: "",
        admins: [],
        users: [],
        assignedKiosks: [],
      },
      rules: {
        nameRules: [(v) => !!v || "Role name is required"],
        descriptionRules: [(v) => !!v || "Description is required"],
      },
    };
  },
  computed: {
    ...mapGetters([
      "allRegisteredUsers",
      "allRegisteredKiosks",
      "userId",
      "userProfile",
    ]),
    usersList() {
      let user = [];
      this.allRegisteredUsers.forEach((users) => {
        user.push(users);
      });
      if (this.addedUsersList.length >= 1) {
        return user.filter((x) => {
          return !this.addedUsersList.some((y) => {
            return x.id === y;
          });
        });
      } else {
        return user;
      }
    },
    kiosks() {
      let kiosks = [];
      this.allRegisteredKiosks.forEach((kiosk) =>
        kiosks.push({ kioskName: kiosk.data().name, kioskId: kiosk.id })
      );
      return kiosks;
    },
  },
  methods: {
    addNewGroup() {
      if (this.$refs.addNewRoleForm.validate()) {
        this.loading = true;
        this.$store
          .dispatch("addNewGroup", {
            name: this.group.name,
            description: this.group.description,
            admins: [],
            users: this.addedUsersList,
            assignedKiosks: this.group.assignedKiosks,
          })
          .then(
            (response) => {
              this.loading = false;
              this.show = false;
              this.$store.dispatch("showSnackBar", {
                text: response,
                color: "success",
              });
              this.group = {
                name: "",
                description: "",
                admins: [],
                users: [],
                assignedKiosks: [],
              };
              if (this.userProfile.data().userRole !== "Super Admin") {
                this.addedUsersList = [this.userId]
              }else{
                this.addedUsersList = []
              }
            },
            (error) => {
              this.$store.dispatch("showSnackBar", {
                text: error,
                color: "error",
              });
            }
          );
      }
    },
    addParticipant(user) {
      this.addedUsersList.push(user.id);
    },
    getUserInitials(id) {
      let userInfo = this.allRegisteredUsers.find((user) => user.id === id);
      let initials = { initial: "", name: "" };
      if (userInfo.data().name !== null || userInfo.data().name !== "") {
        let split = userInfo.data().name.split(" ");
        split.forEach((item) => {
          initials.initial += item[0];
        });
        initials.initial = initials.initial.substring(0, 2);
        initials.name = userInfo.data().name;
      }
      return initials;
    },
    removeUser(id) {
      this.addedUsersList.splice(this.addedUsersList.indexOf(id), 1);
    },
  },
  mounted() {
    if (this.userProfile.data().userRole !== "Super Admin") {
      this.addedUsersList.push(this.userId);
    }
  },
};
</script>