<template>
  <v-dialog v-model="show" max-width="40%">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="editGroup">
        mdi-pencil
      </v-icon>
      <v-icon
        v-bind="attrs"
        v-on="on"
        small
        @click="deleteGroup"
        v-if="isSuperAdmin"
      >
        mdi-delete
      </v-icon>
    </template>
    <v-card>
      <div style="position: absolute; align-self: end; right: 0">
        <v-btn icon @click="show = !show" class="ma-1">
          <v-icon color="red">mdi-window-close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="justify-center">
        {{ formTitle }}
      </v-card-title>
      <v-divider></v-divider>
      <v-progress-linear
        v-show="loading"
        indeterminate
        color="primary"
        background-opacity="0"
      >
      </v-progress-linear>
      <v-card-text v-if="actionType === 'edit'">
        <v-form ref="editGroupForm" lazy-validation class="px-6">
          <v-text-field
            v-model="group.name"
            label="Group Name"
            :rules="rules.nameRules"
            required
          >
          </v-text-field>
          <v-textarea
            outlined
            label="Description"
            v-model="group.description"
          ></v-textarea>
          <v-row>
            <v-col cols="4">
              <v-checkbox label="Assign Kiosk" v-model="assignKiosk">
              </v-checkbox>
            </v-col>
            <v-col cols="8">
              <v-select
                v-if="assignKiosk"
                v-model="group.kiosk"
                :rules="[(v) => !!v || 'Assign at-least one kiosk']"
                :items="kiosks"
                label="Assigned Kiosks"
                item-text="kioskName"
                item-value="kioskName"
                required
                multiple
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip small v-if="index === 0">
                    <span>{{ item.kioskName }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ group.kiosk.length - 1 }} others)
                  </span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-combobox
            v-model="list"
            :items="usersList"
            hide-selected
            multiple
            @change="list = []"
            label="Add Users"
            hide-details=""
            dense
          >
            <template v-slot:item="data">
              <v-list-item-avatar>
                <v-avatar color="primary" size="36" class="white--text">
                  {{ getUserInitials(data.item.id).initial }}
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content @click="addParticipant(data.item)">
                <v-list-item-title
                  v-html="data.item.data().name"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-combobox>
          <v-sheet
            min-height="10"
            max-height="100"
            class="mb-5 mt-5 overflow-y-auto"
            v-if="addedUsersList.length > 0"
          >
            <v-chip
              v-for="(user, i) in addedUsersList"
              :key="i"
              close
              class="ma-2"
              @click:close="removeUser(user)"
            >
              <v-avatar left class="white--text" color="primary">
                {{ getUserInitials(user).initial }}
              </v-avatar>
              {{ getUserInitials(user).name }}
            </v-chip>
          </v-sheet>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="
                show = !show;
                addedUsersList = [];
                removedUsersList = []
              "
            >
              Cancel
            </v-btn>
            <v-btn color="primary" text @click="updateGroup"> Save </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="actionType === 'delete'">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="show = !show">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="confirmDeleteGroup">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "editAndDeleteGroup",
  props: {
    groupIndex: Object,
  },
  data() {
    return {
      assignKiosk: false,
      show: null,
      actionType: "",
      formTitle: "",
      loading: false,
      addedUsersList: [],
      list: [],
      group: {
        name: "",
        description: "",
        id: null,
        users: [],
        kiosk: [],
      },
      rules: {
        nameRules: [(v) => !!v || "Group name is required"],
        descriptionRules: [(v) => !!v || "Description is required"],
      },
      removedUsersList: [],
    };
  },
  computed: {
    ...mapGetters([
      "allRegisteredUsers",
      "allRegisteredKiosks",
      "isSuperAdmin",
    ]),
    usersList() {
      let user = [];
      this.allRegisteredUsers.forEach((users) => {
        user.push(users);
      });
      console.log("User: ", this.addedUsersList.length);
      if (this.addedUsersList.length !== 0) {
        return user.filter((x) => {
          return !this.addedUsersList.some((y) => {
            return x.id === y;
          });
        });
      } else {
        return user;
      }
    },
    kiosks() {
      let kiosks = [];
      this.allRegisteredKiosks.forEach((kiosk) =>
        kiosks.push({ kioskName: kiosk.data().name, kioskId: kiosk.id })
      );
      return kiosks;
    },
  },
  methods: {
    editGroup() {
      console.log("Group Details: ", this.groupIndex);
      this.formTitle = "Edit Group";
      this.actionType = "edit";
      this.group = Object.assign({}, this.groupIndex);
      this.group.id = this.groupIndex.id;
      if (this.groupIndex.kiosk.length > 0) {
        this.assignKiosk = true;
      }
      this.addedUsersList = [...this.groupIndex.users];
    },
    deleteGroup() {
      this.formTitle = "Are you sure you want to delete this group?";
      this.actionType = "delete";
    },
    updateGroup() {
      if (this.$refs.editGroupForm.validate()) {
        this.group.users = [...this.addedUsersList]
        console.log("Updated Group object", this.group);
        this.loading = true;
        this.$store.dispatch("updateGroup", this.group).then(
          (response) => {
            this.loading = false;
            this.show = false;
            this.$store.dispatch("showSnackBar", {
              text: response,
              color: "success",
            });
          },
          (error) => {
            this.$store.dispatch("showSnackBar", {
              text: error,
              color: "error",
            });
          }
        );

        this.removedUsersList.forEach((user) => {
          let data = {
            id: user,
            groupName: this.group.name,
          };
          this.$store.dispatch("removeUserFromGroup", data);
        });
        this.removedUsersList = []

      }
    },
    confirmDeleteGroup() {
      this.loading = false;
      // also delete the group from Users
      this.$store.dispatch("deleteGroup", this.groupIndex).then(
        (response) => {
          this.loading = false;
          this.show = false;
          this.$store.dispatch("showSnackBar", {
            text: response,
            color: "success",
          });
        },
        (error) => {
          this.$store.dispatch("showSnackBar", {
            text: error,
            color: "error",
          });
        }
      );

      //call cloud function here
    },
    addParticipant(user) {
      this.addedUsersList.push(user.id);
    },
    getUserInitials(id) {
      let userInfo = this.allRegisteredUsers.find((user) => user.id === id);
      let initials = { initial: "", name: "" };
      if (userInfo.data().name !== null || userInfo.data().name !== "") {
        let split = userInfo.data().name.split(" ");
        split.forEach((item) => {
          initials.initial += item[0];
        });
        initials.initial = initials.initial.substring(0, 2);
        initials.name = userInfo.data().name;
      }
      return initials;
    },
    removeUser(id) {
      this.removedUsersList.push(id);
      this.addedUsersList.splice(this.addedUsersList.indexOf(id), 1);
      // If exists remove the group name from this user Id.
      // let data = {
      //   userId: id,
      //   groupName: this.groupIndex.name,
      // };
      // this.$store.dispatch("removeUserFromGroup", data);
    },
  },
};
</script>
