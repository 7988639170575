<template>
  <v-container fluid>
    <v-card elevation="0" class="px-3">
      <v-card-title class="text-h4">
        Group Management
        <v-spacer></v-spacer>
        <addNewGroup></addNewGroup>
      </v-card-title>
    </v-card>

    <v-card elevation="0" class="px-3 mt-3">
      <v-card-title>        
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search User Group by Name"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="userGroups"
        :search="search"
      >
        <template v-slot:item.name="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:item.userCount="{ item }">
          {{ item.userCount }}
        </template>
        <template v-slot:item.dateCreated="{ item }">
          {{ item.dateCreated | formatDate }}
        </template>
        <template v-slot:item.lastUpdated="{ item }">
          {{ item.lastUpdated | formatDate }}
        </template>
        <template v-slot:item.description="{ item }">
          {{ item.description | trimLength }}
        </template>
        <template v-slot:item.actions="{ item }">
          <editAndDeleteGroup :groupIndex="item"> </editAndDeleteGroup>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import addNewGroup from "@/components/dashboard/admin/addNewGroup.vue";
import editAndDeleteGroup from '@/components/dashboard/admin/editAndDeleteGroup.vue'
export default {
  name: "groupManagement",
  components: {
    addNewGroup,
    editAndDeleteGroup
  },
  data() {
    return {
        loading: true,
      search: "",
      headers: [
        { text: "Group Name", align: "start", value: "name" },
        { text: "Description", value: "description" },
        { text: "Users", value: "userCount" },
        { text: "Last Updated", value: "lastUpdated" },
        { text: "Date Created", value: "dateCreated" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["userGroups"]),
  },
  mounted() {
    this.loading = false
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
    trimLength(val) {
      if (val.length < 200) {
        return val;
      }
      return `${val.substring(0, 50)}...`;
    },
  },
};
</script>