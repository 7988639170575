var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"40%","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"0","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Add New Group ")],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('div',{staticStyle:{"position":"absolute","align-self":"end","right":"0"}},[(_vm.userProfile.data().userRole !== 'Super Admin')?_c('v-btn',{staticClass:"ma-1",attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show;
          _vm.$refs.addNewRoleForm.reset();
          _vm.addedUsersList = [_vm.userId];}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-window-close")])],1):_c('v-btn',{staticClass:"ma-1",attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show;
          _vm.$refs.addNewRoleForm.reset();
          _vm.addedUsersList = [];}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-window-close")])],1)],1),_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" Add New Group ")]),_c('v-divider'),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":"","color":"primary","background-opacity":"0"}}),_c('v-card-text',[_c('v-form',{ref:"addNewRoleForm",staticClass:"px-6",attrs:{"lazy-validation":""}},[_c('v-text-field',{attrs:{"label":"Group Name","rules":_vm.rules.nameRules,"required":""},model:{value:(_vm.group.name),callback:function ($$v) {_vm.$set(_vm.group, "name", $$v)},expression:"group.name"}}),_c('v-textarea',{attrs:{"outlined":"","label":"Description"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addNewRole($event)}},model:{value:(_vm.group.description),callback:function ($$v) {_vm.$set(_vm.group, "description", $$v)},expression:"group.description"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-checkbox',{attrs:{"label":"Assign Kiosk"},model:{value:(_vm.assignKiosk),callback:function ($$v) {_vm.assignKiosk=$$v},expression:"assignKiosk"}})],1),_c('v-col',{attrs:{"cols":"8"}},[(_vm.assignKiosk)?_c('v-select',{attrs:{"rules":[function (v) { return !!v || 'Assign at-least one kiosk'; }],"items":_vm.kiosks,"label":"Assigned Kiosks","item-text":"kioskName","item-value":"kioskName","required":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var item = ref.item;
          var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item.kioskName))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.group.assignedKiosks.length - 1)+" others) ")]):_vm._e()]}}],null,false,276530409),model:{value:(_vm.group.assignedKiosks),callback:function ($$v) {_vm.$set(_vm.group, "assignedKiosks", $$v)},expression:"group.assignedKiosks"}}):_vm._e()],1)],1),_c('v-combobox',{staticClass:"mb-5",attrs:{"items":_vm.usersList,"hide-selected":"","multiple":"","label":"Add Users","hide-details":"","dense":""},on:{"change":function($event){_vm.group.users = []}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-avatar',[_c('v-avatar',{staticClass:"white--text",attrs:{"color":"primary","size":"36"}},[_vm._v(" "+_vm._s(_vm.getUserInitials(data.item.id).initial)+" ")])],1),_c('v-list-item-content',{on:{"click":function($event){return _vm.addParticipant(data.item)}}},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.data().name)}})],1)]}}]),model:{value:(_vm.group.users),callback:function ($$v) {_vm.$set(_vm.group, "users", $$v)},expression:"group.users"}}),(_vm.addedUsersList.length > 0)?_c('v-sheet',{staticClass:"mb-5 overflow-y-auto",attrs:{"min-height":"10","max-height":"100"}},_vm._l((_vm.addedUsersList),function(user,i){return _c('v-chip',{key:i,staticClass:"ma-1",attrs:{"close":""},on:{"click:close":function($event){return _vm.removeUser(user)}}},[_c('v-avatar',{staticClass:"white--text",attrs:{"left":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.getUserInitials(user).initial)+" ")]),_vm._v(" "+_vm._s(_vm.getUserInitials(user).name)+" ")],1)}),1):_vm._e(),_c('v-btn',{attrs:{"color":"primary","dark":"","block":"","large":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addNewGroup($event)},"click":_vm.addNewGroup}},[_vm._v(" Add Group ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }